/**
 * 可重置Timeout
 */
export class ResettableTimeout {
  private timerId?: NodeJS.Timeout;

  private callback: () => void;

  private ms: number;

  /**
   * 可重置Timeout
   * @param callback 超时回调
   * @param ms 初始超时时间
   */
  constructor(callback: () => void, ms: number) {
    this.callback = callback;
    this.ms = ms;

    // 立即开始计时
    this.start();
  }

  /**
   * 开始
   */
  start() {
    this.timerId = setTimeout(this.callback, this.ms);
  }

  /**
   * 重置
   * @param ms 新的超时时间 (milliseconds)
   */
  reset(ms?: number) {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
    if (ms !== undefined) {
      this.ms = ms;
    }
    this.start();
  }

  /**
   * 销毁
   */
  destroy() {
    if (this.timerId) {
      clearTimeout(this.timerId);
      this.timerId = undefined;
    }
  }
}
