import { createAxios, MessageQueue, RequestOptions } from '@meerkat/utils';
import { ResettableTimeout } from '@meerkat/utils/sse/resettableTimeout';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { cloneDeep } from 'lodash-es';
import { nanoid } from 'nanoid';
import { onBeforeUnmount, ref } from 'vue';

import {
  AIAgentParams,
  AiChatServerConfig,
  AiTransferMessage,
  AiTransferSend,
  AsyncImageResult,
  ImageResult,
} from './types';

export * from './types';

const aiHost = import.meta.env.VITE_AI_HOST;
const request = createAxios({ host: aiHost });

/**
 * AI聊天钩子
 * @param conf 配置
 */
export const useAiChatServer = (conf?: AiChatServerConfig) => {
  const config: AiChatServerConfig = { debug: false, timeout: 1000 * 15 * 60, ...conf };
  const {
    debug,
    getUser,
    onErrorMessage,
    onConnectError,
    onMessageReciveStart,
    onMessageReciveFinish,
    onChainFlowStart,
    onChainFlowEnd,
    onSigleMessageStart,
    onSigleMessageEnd,
    onUpdateRunTips,
    onStreamTextMessage,
    onSyncTextMessage,
    onSyncJsonMessage,
    onSyncImageMessage,
    onAsyncImageMessage,
    onSyncFileUrlMessage,
    onSyncListMessage,
    onSyncSearchMessage,
    onWaitFileMessage,
    onWaitInputMessage,
    timeout,
  } = config;

  onBeforeUnmount(() => {
    closeServer();
  });

  const messageQueue = new MessageQueue<AiTransferMessage>((msg) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        onMessage(msg);
        resolve(true);
      }, 1);
    });
  });
  const sessionId = ref(config.sessionId ?? nanoid());
  let eventSource: EventSourcePolyfill;
  let lastMessage: AiTransferMessage;
  /**
   * 连接聊天服务器
   * @param askId 连接聊天服务器
   */
  function connectChatServer(askId: string) {
    return new Promise<boolean>((resolve, reject) => {
      const user = getUser();
      eventSource = new EventSourcePolyfill(`${aiHost}/mai/createSse`, {
        heartbeatTimeout: 86400 * 1000,
        headers: {
          askid: askId,
          uuid: sessionId.value,
          userId: `${user.id}`,
          token: user.token,
        },
      });

      // sse连接成功
      eventSource.onopen = (e) => {
        if (debug) {
          // eslint-disable-next-line no-console
          console.log('SSE已连接:');
          // eslint-disable-next-line no-console
          console.log(e);
        }

        resolve(true);
      };

      // sse收到消息
      eventSource.onmessage = (e) => {
        messageQueue.enqueue(JSON.parse(e.data) as AiTransferMessage);
      };

      // sse异常
      eventSource.onerror = (e) => {
        const ev = e as ErrorEvent & { status: number; statusText: string };
        reject(ev.error);

        const queue = messageQueue.getQueue();
        if (
          queue.find((v) => v.message === 'AgentLoop End') ||
          (lastMessage && lastMessage.message === 'AgentLoop End')
        ) {
          // 正常断开连接
          // 消息已经全部接收完毕，正在等待处理
          closeServer();
          return;
        }

        // 其他错误
        closeServer();
        if (onConnectError) {
          onConnectError(ev);
        }
        localStop();

        // 打印错误
        if (debug) {
          // eslint-disable-next-line no-console
          console.log('SSE错误:');
          // eslint-disable-next-line no-console
          console.log(ev);
        }
      };
    });
  }

  /**
   * 消息接收回调
   * @param msg 消息数据
   */
  function onMessage(messageRes: AiTransferMessage) {
    if (debug) {
      // eslint-disable-next-line no-console
      console.log(messageRes);
    }

    lastMessage = messageRes;
    resetTimeout(messageRes);

    // 消息错误处理
    if (messageRes.resultType === 'error') {
      if (onErrorMessage) {
        onErrorMessage(messageRes, messageRes.message);
      }
      return;
    }

    // 消息发送成功，准备开始接收
    if (messageRes.message === 'AgentLoop Start') {
      if (onMessageReciveStart) {
        onMessageReciveStart(messageRes);
      }
      return;
    }

    // 全部消息接收完成
    if (messageRes.message === 'AgentLoop End') {
      closeServer();
      if (onMessageReciveFinish) {
        onMessageReciveFinish(messageRes);
      }
      return;
    }

    // 一个回复集合的开始(Chain)
    if (messageRes.message === 'Agent Start') {
      if (onChainFlowStart) {
        onChainFlowStart(messageRes);
      }
      return;
    }

    // 一个回复集合的结束(Chain)
    if (messageRes.message === 'Agent End') {
      if (onChainFlowEnd) {
        onChainFlowEnd(messageRes);
      }
      return;
    }

    // 输出开始通知
    if (['Stream Start', 'Sync Start'].includes(messageRes.message)) {
      if (onSigleMessageStart) {
        onSigleMessageStart(messageRes);
      }
      return;
    }

    // 输出结束通知
    if (['Stream End', 'Sync End'].includes(messageRes.message)) {
      if (onSigleMessageEnd) {
        onSigleMessageEnd(messageRes);
      }
      return;
    }

    // runTips更新消息
    if (messageRes.dataType === 'runTips') {
      if (onUpdateRunTips) {
        onUpdateRunTips(messageRes);
      }
      return;
    }

    // 流式文本消息
    if (messageRes.dataType === 'text' && messageRes.resultType === 'stream') {
      if (onStreamTextMessage) {
        onStreamTextMessage(messageRes, messageRes.message);
      }
      return;
    }

    // 同步文本消息
    if (messageRes.dataType === 'text' && messageRes.resultType === 'sync') {
      if (onSyncTextMessage) {
        onSyncTextMessage(messageRes, messageRes.message);
      }
      return;
    }

    // 同步JSON消息
    if (messageRes.dataType === 'json' && messageRes.resultType === 'sync') {
      if (onSyncJsonMessage) {
        onSyncJsonMessage(messageRes, messageRes.message);
      }
      return;
    }

    // 同步Image地址消息
    if (messageRes.dataType === 'image' && messageRes.resultType === 'sync') {
      // 需要修改
      if (onSyncImageMessage) {
        onSyncImageMessage(messageRes, JSON.parse(messageRes.message) as ImageResult[]);
      }
      return;
    }

    // 异步Image地址消息
    if (messageRes.dataType === 'image' && messageRes.resultType === 'async') {
      // 需要修改
      if (onAsyncImageMessage) {
        onAsyncImageMessage(messageRes, JSON.parse(messageRes.message) as AsyncImageResult);
      }
      return;
    }

    // 同步文件地址消息
    if (messageRes.dataType === 'fileUrl' && messageRes.resultType === 'sync') {
      if (onSyncFileUrlMessage) {
        onSyncFileUrlMessage(messageRes, [{ url: messageRes.message, extension: messageRes.extData.fileType }]);
      }
      return;
    }

    // 同步列表数据消息
    if (messageRes.dataType === 'list' && messageRes.resultType === 'sync') {
      if (onSyncListMessage) {
        onSyncListMessage(messageRes, JSON.parse(messageRes.message));
      }
      return;
    }

    // 搜索结果数据接收
    if (messageRes.dataType === 'search' && messageRes.resultType === 'sync') {
      if (onSyncSearchMessage) {
        onSyncSearchMessage(messageRes, {
          listType: 'search',
          list: messageRes.extData.list,
        });
      }
      return;
    }

    // 等待文件上传消息
    if (messageRes.dataType === 'file' && messageRes.resultType === 'wait') {
      if (onWaitFileMessage) {
        onWaitFileMessage(messageRes, messageRes.extData);
      }
      return;
    }

    // 等待录入消息
    if (messageRes.dataType === 'input' && messageRes.resultType === 'wait') {
      if (onWaitInputMessage) {
        onWaitInputMessage(messageRes, messageRes.extInputData);
      }
    }
  }

  /**
   * 发送消息以开始聊天
   * @param message 消息
   */
  function startCaht(message: AiTransferSend) {
    return new Promise((resolve, reject) => {
      connectChatServer(message.askid)
        .then(() => {
          if (debug) {
            // eslint-disable-next-line no-console
            console.log(cloneDeep(message));
          }

          sendChatMessage(message)
            .then(() => {
              resolve(true);
            })
            .catch((err) => {
              reject(err);
            });
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * 开始PPT大纲生成
   * @param message 消息
   */
  function startOutlineGenerate(message: Required<Pick<AiTransferSend, 'uuid' | 'askid' | 'agentId'>>) {
    return new Promise((resolve, reject) => {
      connectChatServer(message.askid)
        .then(() => {
          if (debug) {
            // eslint-disable-next-line no-console
            console.log(cloneDeep(message));
          }

          sendOutlineGenerateMessage(message)
            .then(() => {
              resolve(true);
            })
            .catch((err) => {
              reject(err);
            });
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * 只发送消息
   * @param message 消息
   */
  function sendMessageOnly(message: AiTransferSend) {
    if (debug) {
      // eslint-disable-next-line no-console
      console.log(cloneDeep(message));
    }

    return new Promise((resolve, reject) => {
      sendChatMessage(message)
        .then(() => {
          if (['fileUpload', 'waitInput'].includes(message.waitType)) {
            resettableTimeout.reset();
          }

          resolve(true);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  let destroying = false;
  /**
   * 停止输出
   * @param message 消息
   */
  function destoryChat(message: AiTransferSend) {
    if (debug) {
      // eslint-disable-next-line no-console
      console.log(cloneDeep(message));
    }

    return new Promise((resolve, reject) => {
      if (destroying) {
        reject(new Error('正在停止'));
        return;
      }

      destroying = true;
      closeServer();

      destoryChatLLM(message).finally(() => {
        setTimeout(() => {
          localStop();
          destroying = false;

          resolve(true);
        }, 1000);
      });
    });
  }

  /**
   * 强制停止
   */
  function destoryChatForce() {
    return new Promise((resolve, reject) => {
      if (destroying) {
        reject(new Error('正在停止'));
        return;
      }

      destroying = true;
      closeServer();

      localStop();
      destroying = false;

      resolve(true);
    });
  }

  /**
   * 手动停止输出
   */
  function localStop() {
    const queue = messageQueue.getQueue();
    if (queue.find((v) => v.message === 'AgentLoop End') || (lastMessage && lastMessage.message === 'AgentLoop End')) {
      return;
    }

    if (queue.find((v) => v.message === 'Agent End') || (lastMessage && lastMessage.message === 'Agent End')) {
      messageQueue.enqueue({ message: 'AgentLoop End' } as AiTransferMessage);
      return;
    }

    if (
      queue.find((v) => ['Stream End', 'Sync End'].includes(v.message)) ||
      (lastMessage && ['Stream End', 'Sync End'].includes(lastMessage.message))
    ) {
      messageQueue.enqueue({
        message: 'Agent End',
        agentId: lastMessage ? lastMessage.agentId : undefined,
      } as AiTransferMessage);
      messageQueue.enqueue({ message: 'AgentLoop End' } as AiTransferMessage);
      return;
    }

    messageQueue.enqueue({ message: 'Sync End' } as AiTransferMessage);
    messageQueue.enqueue({
      message: 'Agent End',
      agentId: lastMessage ? lastMessage.agentId : undefined,
    } as AiTransferMessage);
    messageQueue.enqueue({ message: 'AgentLoop End' } as AiTransferMessage);
  }

  /**
   * 关闭聊天服务器
   */
  function closeServer() {
    if (!eventSource || eventSource.readyState === eventSource.CLOSED) {
      return;
    }

    try {
      eventSource.close();
      resettableTimeout.destroy();
    } catch (error) {
      console.warn(error);
    }
    eventSource = undefined;
  }

  /**
   * 获取指定流程的全部输入输出参数
   * @param message 消息
   */
  function getFlowIoParamers(data: Pick<AiTransferSend, 'askid' | 'agentId' | 'rids'>) {
    return new Promise<AIAgentParams[]>((resolve, reject) => {
      getChainFlowIoParamers({ uuid: sessionId.value, ...data })
        .then((res) => {
          const { status, data, message } = res;
          if (status !== 200) {
            reject(new Error(message));
          }
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * 获取指定流程的全部参数(仅10分钟内生成的有效)
   * @param message 消息
   */
  function getFlowFinalData(askid: string) {
    return new Promise<AIAgentParams[]>((resolve, reject) => {
      getChainFlowFinalData({ uuid: sessionId.value, askid })
        .then((res) => {
          const { status, data, message } = res;
          if (status !== 200) {
            reject(new Error(message));
          }
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * ====================
   *       超时处理
   * ====================
   */
  const resettableTimeout = new ResettableTimeout(() => {
    MessagePlugin.warning('服务器超时');
    destoryChatForce();
  }, timeout);
  /**
   * 重置超时器
   * @param messageRes 消息
   */
  function resetTimeout(messageRes: AiTransferMessage) {
    // 流式消息或节点消息试用常规超时时间
    if (
      ['AgentLoop Start', 'Agent Start', 'Agent End', 'Stream Start', 'Stream End', 'error'].includes(
        messageRes.message,
      ) ||
      (messageRes.dataType === 'text' && messageRes.resultType === 'stream')
    ) {
      resettableTimeout.reset(timeout);
      return;
    }

    // 等待输入类型的消息需要暂时关闭超时器
    if (['file', 'input'].includes(messageRes.dataType) && messageRes.resultType === 'wait') {
      resettableTimeout.destroy();
      return;
    }

    // 全部结束需要关闭超时器
    if (messageRes.message === 'AgentLoop End') {
      resettableTimeout.destroy();
      return;
    }

    // 其他情况需要多加2分钟超时
    resettableTimeout.reset(timeout + 1000 * 60 * 2);
  }

  /**
   * ====================
   *       接口定义
   * ====================
   */
  /**
   * 发送PPT大纲生成消息
   * @param data 请求参数
   * @param options 配置
   */
  function sendOutlineGenerateMessage(
    data: Required<Pick<AiTransferSend, 'uuid' | 'askid' | 'agentId'>>,
    options?: RequestOptions,
  ) {
    const user = getUser();

    return request.post<{ success: '0' | '1' }>(
      { url: '/atk/piplineOutSlash', data, headers: { token: user.token, userId: user.id } },
      { ...options, apiUrl: aiHost, withToken: false, isTransformResponse: false, retry: { count: 0, delay: 0 } },
    );
  }

  /**
   * 发送消息
   * @param data 请求参数
   * @param options 配置
   */
  function sendChatMessage(data: AiTransferSend, options?: RequestOptions) {
    const user = getUser();

    return request.post<{ success: '0' | '1' }>(
      { url: '/mai/callChain', data, headers: { token: user.token, userId: user.id } },
      { ...options, apiUrl: aiHost, withToken: false, isTransformResponse: false, retry: { count: 0, delay: 0 } },
    );
  }

  /**
   * 停止输出
   * @param data 请求参数
   * @param options 配置
   */
  function destoryChatLLM(data: AiTransferSend, options?: RequestOptions) {
    const user = getUser();

    return request.post<{ success: '0' | '1' }>(
      { url: '/mai/destoryLLM', data, headers: { token: user.token, userId: user.id } },
      {
        ...options,
        apiUrl: aiHost,
        withToken: false,
        isTransformResponse: false,
        errorMessage: false,
        retry: { count: 0, delay: 0 },
      },
    );
  }

  /**
   * 获取指定流程的全部输入输出参数
   * @param data 请求参数
   * @param options 配置
   */
  function getChainFlowIoParamers(
    data: Pick<AiTransferSend, 'askid' | 'agentId' | 'uuid' | 'rids'>,
    options?: RequestOptions,
  ) {
    const user = getUser();

    return request.post<{
      data: AIAgentParams[];
      message: string;
      status: 200;
    }>(
      { url: '/mai/getFlowIoParamers', data, headers: { token: user.token, userId: user.id } },
      { ...options, apiUrl: aiHost, withToken: false, isTransformResponse: false, retry: { count: 0, delay: 0 } },
    );
  }

  /**
   * 获取指定流程的全部参数(仅10分钟内生成的有效)
   * @param data 请求参数
   * @param options 配置
   */
  function getChainFlowFinalData(data: { uuid: string; askid: string }, options?: RequestOptions) {
    const user = getUser();

    return request.post<{
      data: AIAgentParams[];
      message: string;
      status: 200;
    }>(
      { url: '/mai/getFinalData', data, headers: { token: user.token, userId: user.id } },
      { ...options, apiUrl: aiHost, withToken: false, isTransformResponse: false, retry: { count: 0, delay: 0 } },
    );
  }

  return {
    sessionId,
    startCaht,
    startOutlineGenerate,
    closeServer,
    sendMessageOnly,
    destoryChat,
    destoryChatForce,
    getFlowIoParamers,
    getFlowFinalData,
  };
};
