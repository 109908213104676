import { App, Directive } from 'vue';

import permissions from './permissions';

const directives: { [key: string]: Directive } = {
  permissions,
};

export default {
  install(app: App) {
    Object.keys(directives).forEach((key) => {
      app.directive(key, directives[key]);
    });
  },
};
