import { DirectiveBinding } from 'vue';

import { useUserStore } from '@/store';

export default {
  mounted(el: HTMLElement, binding: DirectiveBinding) {
    const { value } = binding;
    if (value && typeof value === 'string') {
      const userStore = useUserStore();
      if (!userStore.permissions.includes(value)) {
        if (el.parentNode) {
          el.parentNode.removeChild(el);
        }
      }
    }
  },
};
