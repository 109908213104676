import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "1em",
  height: "1em",
  fill: "none",
  viewBox: "0 0 40 40"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#2F33B0",
      "fill-rule": "evenodd",
      d: "M15.593 40c-.007-7.21-.198-15.77-.269-18.972-.017-.739-.027-1.193-.027-1.277v-.03a5.5 5.5 0 0 1 .032-1.175 5.07 5.07 0 0 1 .375-2.75 5 5 0 0 1 1.764-2.133c1.876-1.269 4.396-1.75 6.107-.6.438.215.915.337 1.401.36a10.6 10.6 0 0 0 2.26-.486s.443-.097.65.072c.474.273 1.215 1.488.417 3.096q-.347.564-.763 1.078c-.128.16-.329.358-.568.596-.714.71-1.774 1.765-2.284 3.111q-.046.123-.072.252l-.01.05c-.013.052-.025.104-.025.159q-.02.114-.022.23l-.021.06q-.135.875-.16 1.759.014.016.024.036c-.028.083-.028.173-.028.266.004.24.018.485.043.72.025.147.025.067.025-.022.32 1.948 1.54 3.286 4.599 5.443 0 0 1.126.99.802 5.25-.016 0-.948 0-1.893-1.154l-.713-1.198-.617-1.65a4.76 4.76 0 0 0 1.07 3.344c.13.163.288.303.466.413.496.306 1.501.874 2.036.777l.064-.011c.558-.1 1.605-.285 2.187.603a20.28 20.28 0 0 0 7.72-15.963C40.163 9.068 31.173 0 20.082 0S0 9.068 0 20.254C0 29.884 6.663 37.944 15.593 40m.984-36.365c-.182 2.337-.203 3.722.852 6.472.104.266.606.205.631-.082.25-2.877.129-3.859-.78-6.473-.107-.316-.678-.237-.703.083m-6.264 3.42c1.872 1.387 2.924 2.28 4.278 4.872.132.255-.245.596-.474.431-2.317-1.7-2.97-2.427-4.331-4.807-.16-.299.267-.69.527-.496m-4.017 6.741c1.668 1.079 2.691 1.608 5.18 1.669.246 0 .41-.486.206-.619-2.05-1.33-2.823-1.59-5.154-1.736-.271-.018-.467.553-.232.686",
      "clip-rule": "evenodd"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#2F33B0",
      d: "m27.539 39.065-.245-.038c-3.444-.482-4.054-1.273-4.054-1.273a4 4 0 0 1-2.053-2.237l.574.331.613.36c1.773 1.223 4.596 2.697 6.894 2.061q-.842.438-1.73.796"
    }, null, -1)
  ])))
}
export default { render: render }